(function () {
    document.addEventListener('click', function(e) {
        // Quand on clique sur une image de la mosaïque
        if (e.target.closest('.btn-gallery')) {
            const button = e.target.closest('.btn-gallery');
            const overlayId = button.getAttribute('data-overlay-open').replace('overlay-gallery-', '');
            const imageId = button.getAttribute('data-image-id');
            
            // On attend que l'overlay soit ouvert
            setTimeout(() => {
                const targetImage = document.getElementById(`gallery-image-${overlayId}-${imageId}`);
                if (targetImage) {
                    targetImage.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 100);
        }
    });
})();


